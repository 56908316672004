<template>
  <div>
      <!-- Header section -->
      <el-row :gutter="0">
          <el-col :span="20" :offset="2">
              <header class="page-header">
                  <el-row :gutter="20" align="middle">
                    <el-col :span="12">
                        <h2>Брэндийн жагсаалт</h2>
                    </el-col>
                    <el-col :span="12">
                        <div class="text-right">
                                <router-link to="/brand-add">
                                    <el-button type="success" class="item">Нэмэх</el-button>
                                </router-link>
                        </div>
                    </el-col>
                  </el-row>
              </header>
              <!-- Core content -->
              <div class="panel tabled">
                  <el-tabs v-model="activeTabStatus">
                      <el-tab-pane label="Бүгд" name="all">
                          <div class="panel-content">
                              <div class="pl20 pr20 table-filter mb10">
                                  <el-row :gutter="10" align="middle" type="flex">
                                      <el-col :span="20">
                                          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                                          </el-input>
                                      </el-col>
                                      <el-col :span="6">
                                        <el-select v-model="selectStatus" @change="filterStatus" placeholder="Төлөв сонгох">
                                        <el-option
                                        v-for="(isActive, index) in statusSelect"
                                        :key="index"
                                        :label="isActive.label"
                                        :value="isActive.value"
                                        >
                                        </el-option>
                                        </el-select>
                                        </el-col>
                                      <el-col :span="4" :offset="4" class="text-right">
                                        <el-dropdown @command="handleSort">
                                            <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                      </el-col>
                                  </el-row>
                              </div>
                          </div>
                          <div class="panel-content">
                            <el-table
                            :data="brandList"
                            style="width: 100%"
                            v-loading="isLoading"
                            @cell-click="clickCell"
                            >
                            <el-table-column label="Зураг">
                              <template slot-scope="scope">
                                <el-row :gutter="10" align="middle" type="flex">
                                  <el-col :span="12">
                                    <div class="image-holder" style="height: 80px; width: 80px;">
                                      <img :src="scope.row.brand_logo">
                                    </div>
                                  </el-col>
                                </el-row>
                              </template>
                            </el-table-column>
                            <el-table-column label="Монгол нэр"
                              prop="brand_monName"
                            ></el-table-column>
                            <el-table-column label="Англи нэр"
                              prop="brand_engName"
                            ></el-table-column>
                            <el-table-column label="Төлөв">
                            <template slot-scope="scope">
                            <el-tag v-if="scope.row.isActive" type="success">Идэвхтэй</el-tag>
                            <el-tag v-else type="warning">Идэвхгүй</el-tag>
                            </template>
                            </el-table-column>
                            <el-table-column label="Нийлүүлэгч"
                              prop="supplier_mon_name"
                            ></el-table-column>
                            </el-table>
                            <el-pagination
                              @current-change = "curentPageHandler"
                              @size-change = "sizeChangeHandler"
                              class="text-right mt10"
                              background
                              :current-page.sync="currentPage"
                              :page-size="pageSize"
                              :page-sizes="[5, 20, 50, 100]"
                              layout="total, sizes, prev, pager, next"
                              :total="totalCount">
                            </el-pagination>
                          </div>
                      </el-tab-pane>
                  </el-tabs>
              </div>
          </el-col>
      </el-row>
  </div>
</template>

<script>
import services from '../../../helpers/services'
export default {
  data () {
    return {
      field: '',
      sort: '',
      selectStatus: '',
      sortName: 'Эрэмбэлэх',
      search: '',
      brandList: [],
      activeTabStatus: 'all',
      statusFilter: 'all',
      isLoading: false,
      pageSize: 20,
      totalCount: 0,
      currentPage: 1,
      statusSelect: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'true',
        label: 'Идэвхтэй'
      }, {
        value: 'false',
        label: 'Идэвхгүй'
      }],
      dropdownDataFilter: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ]
    }
  },

  created () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getBrands(from, size, this.search, this.field, this.sort)
  },

  methods: {
    onSearch () {
      this.$router.push({ name: 'brandList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getBrands(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'brandList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getBrands(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'brandList', query: { page: item, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getBrands(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'brandList', query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort } }).catch(() => {})
      this.getBrands(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort)
    },

    filterStatus () {
      this.currentPage = 1
      this.getBrands(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.field, this.sort, this.isActive)
    },

    getBrands (from, size, search, field, sort) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort + '&isActive=' + this.selectStatus
      this.isLoading = true
      services.getBrands(query).then(data => {
        this.brandList = data.datas
        this.totalCount = data.total
        this.isLoading = false
      })
    },

    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'editBrand',
          params: {
            brand_id: row.brand_id
          },
          query: { page: this.currentPage, size: this.pageSize, search: this.search, field: this.field, sort: this.sort }
        }
      )
    }
  }
}
</script>

<style>

</style>
